<template>
<div>
    <div v-show="isLoading" class="preloader"></div>
    <div class="row align-item-center">
        <div class="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat" style="background-image: url(/images/bg/sec1-maskot.png);"></div>
        <div class="col-xl-7 min-vh-100 align-items-center bg-white rounded-lg overflow-hidden">
            <div class="row min-vh-100  overflow-auto">
                <div class="col-12 align-items-center d-flex">
                    <div class="card shadow-none border-0 ml-auto mr-auto login-card">
                        <div class="card-body rounded-0 text-left">
                            <div class="text-center mb-3">
                                <img :src="'/images/logo.png'" width="80" height="80" class="rounded" alt="logo">
                            </div>                        
                            <form @submit.prevent="onRegister" class="p-0" style="height: 100%">
                                
                                <div class="form-group icon-input mb-1">
                                    <i class="font-sm ti-user fw-600 text-grey-700 pr-0"></i>
                                    <input type="text" required class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Name" v-model="name">                        
                                </div>
                                <div class="form-group icon-input mb-1">
                                    <i class="font-sm ti-tag fw-600 text-grey-700 pr-0"></i>
                                    <input type="text" required class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Username" v-model="username">                        
                                </div>
                                <div class="form-group icon-input mb-1">
                                    <i class="font-sm ti-email fw-600 text-grey-700 pr-0"></i>
                                    <input type="email" required class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Email Address" v-model="email">                        
                                </div>
                                <div class="form-group icon-input mb-1">
                                    <span class="font-xsss fw-600 text-grey-700 pr-0">+62</span>
                                    <input type="tel" min="11" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$" required class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Phone Number" v-model="phone">                        
                                </div>
                                <div class="form-group icon-input mb-1">
                                    <input :type="isVisible ? 'text' : 'password'" required class="style2-input pl-5 form-control text-grey-900 font-xss ls-3" placeholder="Password" v-model="password">
                                    <i class="font-sm ti-lock fw-600 text-grey-700 pr-0"></i>
                                </div>
                                <div class="form-group icon-input mb-1">
                                    <input :type="isVisible ? 'text' : 'password'" required class="style2-input pl-5 form-control text-grey-900 font-xss ls-3" placeholder="Confirm Password" v-model="password_confirmation">
                                    <i class="font-sm ti-lock fw-600 text-grey-700 pr-0"></i>
                                </div>
                                <div class="form-check text-left mb-1">
                                    <input type="checkbox" v-model="isVisible" class="form-check-input mt-2" id="exampleCheck2">
                                    <a href="javascript:void(0)" @click="isVisible = !isVisible" class="form-check-label font-xsss fw-500 text-grey-700" for="exampleCheck2">Show Password</a>
                                </div>
                                <div class="form-check text-left mb-1">
                                    <input type="checkbox" class="form-check-input mt-2" id="exampleCheck1" required>
                                    <label class="form-check-label font-xsss fw-500 text-grey-700" for="exampleCheck1">Accept Term and Conditions</label>
                                </div>
                                
                                <div class="col-sm-12 p-0 text-center">
                                    <div class="mb-0"><input type="submit" value="Register" class="form-control btn-current text-center style2-input fw-600 p-0 "></div>
                                    <h6 class="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Already have account <router-link to="/login" class="fw-700 ml-1 text-warning">Sign in</router-link></h6>
                                    <h6 class="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32 text-center"><router-link to="/browse" class="fw-700 ml-1 text-warning">Back to Homepage</router-link></h6>
                                </div>
                            </form>
                                
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<style scoped>
.form-group.icon-input span {
    position: absolute;
    left: 15px;
    top: 18.5px;
}
</style>
<script>
    import axios from 'axios'
    axios.defaults.withCredentials = true

    export default {
        name: "Register",
        data() {
            return {
                name: '',
                username: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
                validationErrors: '',
                verification_at: null,
                isLoading: false,
                isVisible: false
            }
        },
        methods: {
            onRegister() {
                this.isLoading = true
                axios.post(`${process.env.VUE_APP_URL_API}/register`, {
                    name: this.name,
                    username: this.username,
                    email: this.email,
                    phone: '+62' + this.phone,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                }).then(response => {
                    localStorage.setItem('token', response.data.token)
                    this.$router.push({ name: 'Verification' })
                }).catch(error => {
                    this.isLoading = false
                    if (error.response.status == 422){
                        this.validationErrors = Object.values(error.response.data.errors);
                        this.$swal({
                            toast: true,
                            title: 'Sign Up Failed !',
                            text: this.validationErrors[0][0],
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                })
            }
        }, 
    }
</script>
